* {
    box-sizing: border-box;
}

.RaceCard {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0;
    margin: 0;
    border: black solid 1px;
    background-size: cover;
    background-position: center top;
    position: relative;
    display: grid;
    grid-template-rows: [card-top] 1.2em [weekend-top] 1.5em [location-top] 1.4em [predictions-top] 1fr [sessions-top] 1.4em [card-bottom];
    grid-template-columns: [left-edge] 1fr [dateweather-edge] 6em [right-edge];
    width: 100%;
    height: 15em;
    grid-gap: 0;
}

.TitleSerie {
    grid-area: card-top / left-edge/ weekend-top / right-edge;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: skyblue;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8em;
    background-color: rgba(0, 0, 0, 0.7);
    margin: 0;
    padding: 0.2em 5px 0 5px;
}

.TitleWeekend {
    grid-area: weekend-top / left-edge / location-top / right-edge;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.2em;
    background-color: rgba(0, 0, 0, 0.7);
    margin: 0;
    padding: 0 5px 0 5px;
}

.TitleLocation {
    grid-area: location-top / left-edge / predictions-top / right-edge;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: skyblue;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8em;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(64, 64, 64, 0.6) 100%);
    margin: 0;
    padding: 0 5px 0 5px;
}

.TitleLocationOnClick {
    cursor: pointer;
}

.Predictions {
    grid-area: predictions-top / left-edge / sessions-top / right-edge;
    background-color: rgba(64, 64, 64, 0.6);
    height: 100%;
    width: 100%;
    padding: 0 5px 0 5px;
}

.PredictionsTimes {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 0.9em;
    color: white;
}

.PredictionsSession {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 0.9em;
    color: white;
}

.PredictionsZoom {
    float: right;
    color: white;
    font-size: 1em;
    font-weight: 300;
    cursor: pointer;
    z-index: 3;
}

.WeatherDisplayTimes {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 0.9em;
}

.WeatherDisplaySession {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 0.9em;
}

.WeatherDisplayTable, .WeatherDisplayTableLight {
    font-family: 'Lato', sans-serif;
    color: white;
    font-size: 0.9em;
}

.WeatherDisplayTable {
    font-weight: 400;
}

.WeatherDisplayTableLight {
    font-weight: 300;
    color: lightskyblue;
}

.Sessions {
    grid-area: sessions-top / left-edge / card-bottom / right-edge;
    background-image: linear-gradient(rgba(64, 64, 64, 0.6) 0%, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.5) 100%);
    text-align: center;
    color: lightskyblue;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 0.9em;
    white-space: nowrap;
    text-overflow: fade;
    overflow: hidden;
    margin: 0;
    padding: 5px 5px 0 5px;
}

.SessionsNotSelected {
    cursor: pointer;
}

.SessionsSelected {
    color: white;
    font-weight: bold;
}

.SessionsNow {
    font-style: italic;
}


.UpdateTime {
    grid-area: card-top / dateweather-edge / weekend-top / right-edge;
    z-index: 1;
    justify-self: right;
    font-size: 0.6em;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: white;
    width: fit-content;
    background: none;
}

.CurrentWeather {
    grid-area: weekend-top / dateweather-edge / location-top / right-edge;
    z-index: 1;
    justify-self: right;
    font-size: 0.9em;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: white;
    width: content-box;
    line-height: 1.8em;
    background: none;
    margin: 0;
}

.CurrentWeatherNow {
    font-size: 0.8em;
    font-weight: 300;
}

/*.CurrentWeather p {*/
/*    margin: 0;*/
/*}*/


.EmptyCard {
    flex-grow: 1;
    padding: 0;
    margin: 1px;
    height: 1px;
    overflow: hidden;
}

.FlashThis {
    animation: fadeUpdate 3s;
}

@keyframes fadeUpdate {
    0% {
        color: white;
        opacity: 1;
    }
    5% {
        opacity: 0.5;
        color: white;
    }
    10% {
        color: lightskyblue;
        opacity: 0.5;
    }
    20% {
        opacity: 1;
    }
    75% {
        color: lightskyblue;
    }
    100% {
        color: white;
    }
}
