.About {
    width: 100%;
    margin-top: 0;
    margin-bottom: 30px;
}

.AboutCard {
    width: 90%;
    margin: 5%;
    background-color: skyblue;
    color: navy;
    padding: 10px;
}

h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 1.4em
}

h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 1.2em
}

.AboutText {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1em;
    /*background-color: lightskyblue;*/
}