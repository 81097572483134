body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: midnightblue;
    background-image: -webkit-gradient(linear, left top, left bottom, from(black), to(midnightblue));
    background-image: -webkit-linear-gradient(black, midnightblue);
    background-image: -o-linear-gradient(black, midnightblue);
    background-image: linear-gradient(black, midnightblue);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
}
