.Home {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
}

.RaceCardContainer {
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0;
    border: black solid 1px;
    /*height: 250px;*/
    background-size: cover;
    background-position: center top;
    position: relative;
}

.RaceCardUpdateTime {
    position: absolute;
    display: inline-block;
    font-size: 0.75em;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: white;
    text-align: right;
    top: 0;
    right: 5px;
    margin: 0;
    padding: 0;
    clear: none;
}

.RaceCardCurrent {
    position: absolute;
    top: 2em;
    right: 5px;
    display: block;
    font-size: 0.8em;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: white;
    text-align: right;
    float: right;
    margin: 0;
    padding: 0;
    z-index: 5;
    text-shadow: 0 0 3px lightskyblue;
}

/* ROW 1 */

.RaceCardTitle {
    position: static;
    display: block;
    top: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0.5) 100%);
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 1em;
    height: 60px;
}

.RaceCardTitleSerie {
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: skyblue;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 5px 0 5px;
    margin: 0 0 0 0;
    font-size: 0.8em;
    width: 100%;
}

.RaceCardTitleWeekend {
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    color: white;
    font-weight: bold;
    padding: 0 5px 0 5px;
    margin: -5px 0 0 0;
    font-size: 1.2em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.RaceCardTitleLocation {
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: skyblue;
    padding: 0 5px 0 5px;
    /*margin: -5px 0 -5px 0;*/
    transform: translateY(-5px);
    font-size: 0.8em;
    width: 100%;
}

/* ROW 2*/

.WeatherText, .WeatherChart {
    box-sizing: border-box;
    position: static;
    display: inline-block;
    color: white;
    margin: 0;
    padding: 0 5px 0 5px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 0.2) 100%);
    height: 130px;
    width: 100%;
}

.WeatherDisplayTimes {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 0.9em;
}

.WeatherDisplaySession {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 0.9em;
}

.WeatherDisplayTable, .WeatherDisplayTableLight {
    font-family: 'Lato', sans-serif;
    color: white;
    font-size: 0.9em;
}

.WeatherDisplayTable {
    font-weight: 400;
}

.WeatherDisplayTableLight {
    font-weight: 300;
    color: lightskyblue;
}

.WeatherDisplayHr {
    height: 1px;
    border: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
    margin: 4px auto;
}

/* ROW 3*/

.RaceCardSessions {
    margin: 0;
    padding: 0 5px 0 5px;
    color: lightskyblue;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1em;
    white-space: nowrap;
    text-overflow: fade;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    position: static;
    height: 1.1em;
    text-align: center;
}

.RaceCardSessionsNotSelected {
    cursor: pointer;
}

.RaceCardSessionsSelected {
    color: white;
    font-weight: bold;
}

.RaceCardSessionsNow {
    font-style: italic;
}

/* EXTRAS */

.EmptyCard {
    flex-grow: 1;
    padding: 0;
    margin: 1px;
    height: 1px;
    overflow: hidden;
}

.FlashThis {
    animation: fadeUpdate 3s;
}

@keyframes fadeUpdate {
    0% {
        color: white;
        opacity: 1;
    }
    5% {
        opacity: 0.5;
        color: white;
    }
    10% {
        color: lightskyblue;
        opacity: 0.5;
    }
    20% {
        opacity: 1;
    }
    75% {
        color: lightskyblue;
    }
    100% {
        color: white;
        opacity: 1;
    }
}

.DarkSky {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 0 -5px 0;
    padding: 0;
    border-radius: 6px 0 0 0;
}

.OpenWeather {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 4px;
    border-radius: 6px 0 0 0;
    z-index: 999;
    color: white !important;
    font-family: 'Lato', sans-serif;
    font-size: 0.9em;
    font-weight: bold;
}

.WeatherAPI {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 4px;
    border-radius: 6px 0 0 0;
    z-index: 999;
    color: white !important;
    font-family: 'Lato', sans-serif;
    font-size: 0.9em;
    font-weight: bold;
}

.WeatherServiceSwitch {
    color: white;
    top: 100%;
    margin-right: 2px;
    cursor: pointer;
}

.DarkSky>.WeatherServiceSwitch {
    transform: translateY(-50%);
}

.OpenWeather img {
    height: 20px;
}

.WeatherAPI img {
    height: 35px;
}

.Title {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 7px;
    color: white !important;
    font-family: 'Lato', sans-serif;
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 0 6px 0 0;
}

.TitleInfo {
    color: white
}