.header {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: midnightblue;
    height: 40px;
    position: fixed;
    z-index: 4;
    top: 0;
    width: 100%;
}

.headertitle {
    position: absolute;
    font-size: 1em;
    color: white;
    top: 5px;
    left: 5px;
    z-index: 5;
    cursor: pointer;
    padding-top: 7px;
}

.headerbuttonbar {
    position: absolute;
    font-size: 0.9em;
    color: white;
    top: 6px;
    right: 5px;
    z-index: 5;
    padding-top: 7px;
}

.headerbutton {
    background-color: rgba(135, 206, 250, 0.3);
    border-radius: 6px;
    display: inline;
    padding: 5px 10px 5px 10px;
    margin: 0 5px 0 5px;
    cursor: pointer;
}

.pickerlist {
    display: block;
    position: fixed;
    top: 25px;
    height: auto;
    z-index: 25;
    font-size: 1em;
    color: white;
    background-color: black;
}

.pickerlistitem {
    padding: 0;
    margin: 0;
    cursor: pointer;
}
