html {
    margin-top: 40px;
}

.AdminTitle {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    color: white;
    padding: 5px 20px 5px 5px;
}

.ListTable {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    line-height: 1.5em;
}

.ListRow {
    padding: 5px;
    color: lightskyblue;
}

.ListRow:hover td {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.ListLineCell {
    background: navy;
    padding: 5px;
}

.ListLineCell a:link, a:visited {
    background: navy;
    padding: 5px;
    color: white;
    text-decoration: none;
}

.ListLineCell a:hover, a:active {
    background: lightskyblue;
    color: navy;
    padding: 5px;
}

.EditLine {
    background: navy;
    padding: 1px;
    color: white;
    text-decoration: none;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
}

.EditInputText,.NASCARInputText {
    width: 25em;
    color: navy;
    padding: 5px;
    border: none;
    margin: 5px;
}

.EditInputText {
    background: lightskyblue;
}

.NASCARInputText {
    background: lightgreen;
}

.EditInputButton {
    width: 5em;
    background: navy;
    color: lightskyblue;
    padding: 5px;
    margin: 5px;
}

.FlashSuccess {
    background-color: green !important;
}